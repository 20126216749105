<template>
  <div>
    <NotifyModal
      :show="addToNotifyModal"
      :product-sku="product.sku"
      @close="closeNotifyModal"
    />
    <b-modal
      :id="'modal' + product.sku"
      v-model="addToCartModal"
      content-class="product-popup bundle"
      centered
      :hide-footer="true"
      size="lg"
    >
      <div class="product-popup-body">
        <img :src="product.thumbnail.url" />
        <div class="info-popup">
          <h2>{{ product.name }}</h2>
          <div class="info-popup-wrap">
            <div class="info-wrap">
              <p class="my-price">
                {{ formatCurrency(priceRange.maximum_price.final_price.value) }}
              </p>
              <p>{{ $t("number") }} : {{ quantity }}</p>
            </div>
            <div class="action-btn">
              <b-link class="c-btn c-btn-primary" @click="clickOk">{{
                $t("continue_buying")
              }}</b-link>
              <b-link class="btn-link" @click="clickCancel"
                ><b-icon icon="chevron-left"></b-icon>
                {{ $t("continue_shopping") }}</b-link
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="price-part">
      <div class="out-stock-box" :class="stockStatusClass">
        <h3>{{ $t(isInStock ? "In Stock" : "Out of Stock") }}</h3>
      </div>
      <div class="left" v-if="isInStock">
        <div class="quantity-and-btn">
          <label>{{ $t("number") }}</label>
          <div class="quantity-input">
            <b-link class="step-down-btn" @click="quantityDown"></b-link>
            <input
              type="number"
              min="1"
              :value="quantity"
              name="quantity"
              ref="inputQuantity"
              disabled
            />
            <b-link class="step-up-btn" @click="quantityUp"></b-link>
          </div>
        </div>
      </div>

      <div class="bundle-price">
        <div
          v-if="
            priceRange.minimum_price.final_price.value !=
            priceRange.maximum_price.final_price.value
          "
          class="top-wrap"
        >
          <div class="price-selection">
            <span class="currentPrice"
              >{{
                formatCurrency(priceRange.minimum_price.final_price.value)
              }}
              -{{
                formatCurrency(priceRange.maximum_price.final_price.value)
              }}</span
            >
            <span
              class="discount-comment"
              v-if="priceRange.minimum_price.discount.percent_off > 0"
              >{{ $t("discount") }}
              {{
                priceRange.minimum_price.discount.percent_off.toFixed(0)
              }}%</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="productBundles && productBundles.length > 0">
      <div class="col">
        <div class="bundel-product-wrap">
          <div class="bundel-product-inner">
            <div
              class="bundel-product"
              v-for="(bundleProd, idx) of productBundles"
              :key="`bund` + bundleProd.option_id"
            >
              <div class="pro-title">
                {{ bundleProd.title }}
              </div>
              <template v-if="bundleProd.options && bundleProd.options.length">
                <div v-if="bundleProd.options.length == 1" class="pro-option">
                  <!-- <div class="bundel-img">
                    <img
                      v-if="bundleProd.options[0].product != null"
                      :src="bundleProd.options[0].product.image.url"
                    />
                  </div> -->
                  <span>{{ bundleProd.options[0].label }}</span>
                  <span class="pro-price">
                    + {{ formatCurrency(bundleProd.options[0].price) }}</span
                  >
                </div>
                <div
                  v-for="subOption in bundleProd.options"
                  :key="subOption.id"
                  class="pro-option"
                  v-else
                >
                  <label class="custom-radio-input">
                    <input
                      type="radio"
                      :id="`option${subOption.id}`"
                      :name="'option' + idx"
                      v-on:click="
                        processOption(bundleProd.option_id, idx, subOption.id)
                      "
                      :checked="
                        selectedBundles[idx].option_selection_id == subOption.id
                      "
                    />
                    <div class="custom-radio-input--btn font-weight-normal">
                      <!-- <div class="bundel-img">
                        <img
                          v-if="subOption.product != null"
                          :src="subOption.product.image.url"
                        />
                      </div> -->
                      <span>{{ subOption.label }}</span>
                      <span class="pro-price">
                        + {{ formatCurrency(subOption.price) }}</span
                      >
                    </div>
                  </label>
                </div>
                <div class="pro-input">
                  <label>Quantity</label>
                  <input
                    type="text"
                    @change="
                      changeOptionQuantity($event, bundleProd.option_id, idx)
                    "
                    :value="selectedBundles[idx].quantity"
                    :disabled="
                      bundleProd.options[0].can_change_quantity == false
                    "
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="isInStock"
      v-on:click="addToCart"
      v-bind:disabled="btnDisable"
      :style="buttonClass"
      class="product-parameter-submit-btn"
    >
      <i class="fas fa-shopping-bag"></i>
      {{ $t("add_to_cart") }}
    </button>
    <button
      v-else
      v-on:click="addToNotify"
      class="product-parameter-submit-btn"
    >
      <i class="fas fa-envelope"></i>
      {{ $t("notify_btn") }}
    </button>

    <div class="comon-wrap">
      <div class="whish-list-add" @click.stop="toggleIsInWishList">
        <div class="heart-icon" :class="{ selected: isInWishList }">
          <i class="fas fa-heart"></i>
        </div>

        <span>{{ $t("show_compararison") }}</span>
      </div>
    </div>
    <span
      class="delivery-info d-block"
      v-if="product.DeliveryTime != null"
      :style="'color: ' + product.DeliveryTime.color + '!important'"
    >
      {{ product.DeliveryTime.long }}</span
    >
    <div v-if="availability.length > 0">
      <span class="d-block mb-2">{{ $t("store_availability") }}:</span>
      <div
        class="d-flex flex-column flex-md-row justify-content-between more-info"
      >
        <div class="available-stores">
          <div
            v-for="(astore, index) in availability"
            :key="`astores-${index}`"
          >
            <span class="d-block">{{ astore.store.name }}</span>
          </div>
        </div>
        <div class="stock">
          <div
            v-for="(astore, index) in availability"
            :key="`sstores-${index}`"
          >
            <span v-if="astore.has_stock == true" class="d-block text-info">{{
              $t("in_stock")
            }}</span>
            <span v-if="astore.has_stock != true" class="d-block">{{
              $t("not_in_stock")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="product-extra-info"
      v-html="product.short_description.html"
    ></div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
import { Logger } from "@storefront/core/lib/logger";
import NotifyModal from "@/base/core/components/common/NotifyModal.vue";

export default {
  name: "ProductParametersBundle",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    NotifyModal,
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      productBundles: "product/getCurrentBundle",
      selectedBundles: "product/getSelectedBundles",
      options: "product/getCurrentBundleOptions",
      compareList: "compare/getProductCompare",
      cartItems: "cart/getCartItems",
    }),
    isInStock() {
      if (this.productBundles.length) {
        const status = this.productBundles.every((item) =>
          item.options.every(
            (item1) => item1.product.stock_status == "IN_STOCK"
          )
        );
        return status;
      }
      return this.product.stock_status == "IN_STOCK" ? true : false;
    },
    stockStatusClass() {
      return this.isInStock ? "in-stock" : "out-stock";
    },
    isProductInCompare() {
      return !!this.compareList.find(
        (product) => product.sku === this.product.sku
      );
    },
    btnDisable() {
      return false;
      /*
      let reval = false;
      const bundle = this.$store.getters["product/getCurrentBundle"];
      Logger.debug("ProductParametersBundle", "btnDisable", bundle)();
      const parentSkus = this.$store.getters["product/getSelectedBundles"];
      const childSkus = this.$store.getters["product/getSelectedChildSkus"];
      bundle.forEach((bundleOption) => {
        Logger.debug(
          "ProductParametersBundle",
          "btnDisable",
          parentSkus[bundleOption.option_id]
        )();
        if (parentSkus[bundleOption.option_id] == null) {
          reval = true;
        }
        if (childSkus[bundleOption.option_id] == null) {
          reval = true;
        }
      });
      return reval;
      */
    },
    buttonClass() {
      if (this.btnDisable == true) {
        return "cursor: not-allowed;";
      } else {
        return "";
      }
    },
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    priceRange() {
      return this.product.price_range;
    },
    availability() {
      if (this.product.store_availability) {
        return this.product.store_availability;
      }
      return [];
    },
  },

  data: () => ({
    quantity: 1,
    popup: false,
    addToCartModal: false,
    addToNotifyModal: false,
    addToCompareModal: false,
    priceSize: "",
  }),
  methods: {
    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    clickOk() {
      this.addToCartModal = false;
      this.$router.push("/checkout");
    },
    clickCancel() {
      this.addToCartModal = false;
      window.scrollTo(0, 0);
    },
    clickOkCompare() {
      this.addToCompareModal = false;
      this.$router.push("/compare");
    },
    clickCancelCompare() {
      this.addToCompareModal = false;
      window.scrollTo(0, 0);
    },
    async onCompareClick() {
      if (this.isProductInCompare == false) {
        await this.$store.dispatch("compare/addCompareProduct", {
          sku: this.product.sku,
        });
        this.addToCompareModal = true;
      } else {
        await this.$store.dispatch("compare/removeCompareProduct", {
          sku: this.product.sku,
        });
      }
    },
    closeNotifyModal() {
      this.addToNotifyModal = false;
    },
    quantityUp() {
      this.quantity++;
    },
    quantityDown() {
      if (this.quantity > 1) this.quantity--;
    },
    processOption(bundle_id, index, value) {
      const quantity = this.selectedBundles[index].quantity;
      this.$store.commit("product/setBundleOptionValue", {
        bundle_id: bundle_id,
        index: index,
        value: value,
        quantity: quantity,
      });
    },
    changeOptionQuantity(event, bundle_id, index) {
      const quantity = parseInt(event.target.value);
      const selectedOption = this.selectedBundles[index].option_selection_id;

      this.$store.commit("product/setBundleOptionValue", {
        bundle_id: bundle_id,
        index: index,
        value: selectedOption,
        quantity,
      });
    },
    addToNotify() {
      this.addToNotifyModal = true;
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "BundleProduct": {
          const selected_options =
            this.$store.getters["product/getSelectedBundlesOptions"];

          let items =
            "{ quantity: " +
            this.quantity +
            ' sku: "' +
            this.product.sku +
            '" selected_options: ' +
            JSON.stringify(selected_options) +
            " }";

          const retval = await this.$store.dispatch("cart/addProductToCart", {
            items: items,
          });

          Logger.debug("retval", "addToCart", retval)();

          if (this.$gtm.enabled()) {
            Logger.debug("Watch Product", "product.vue", "CALLED")();
            window.dataLayer.push({
              event: "eec.add",
              ecommerce: {
                add: {
                  products: [
                    {
                      name: this.product.name,
                      id: this.product.sku,
                      quantity: this.quantity,
                    },
                  ],
                },
              },
            });
            if (this.$gtm.ga4Enabled) {
              window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  add: {
                    currency: "EUR",
                    value: this.product.final_price,
                    items: [this.getGTMItems()],
                  },
                },
              });
            }
          }
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.addToCartModal = true;
            setTimeout(() => {
              this.addToCartModal = false;
            }, 5000);
            // this.$bvModal.show("modal-1");
          }
          break;
        }

        default:
          break;
      }
    },
  },
};
</script>
